import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Edit = ({ playground, onBack }) => {
    const [form, setForm] = useState(playground);
    const [imageIds, setimageIds] = useState([]);

    useEffect(() => {
        const handleNewResponse = async () => {
            try {
                const response = await axios.get(
                    'https://sdfsdfsdf1.ru/manage/ground',
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                'authToken'
                            )}`,
                            'Content-Type': 'application/json',
                        },
                        params: {
                            id: playground.id,
                        },
                    }
                );

                const images = response.data.result.images.map(
                    (image) => image.link
                );

                const imageIds = response.data.result.images.map(
                    (image) => image.id
                );

                const isFree = response.data.result.isFree ? 'true' : 'false';

                setForm({ ...response.data.result, images, isFree });
                setimageIds(imageIds);
                //console.log(response.data.result.images);
            } catch (error) {
                console.error(error);
            }
        };
        handleNewResponse();
    }, [playground.id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
    };

    const handleRemoveGround = async () => {
        try {
            const response = await axios.get(
                'https://sdfsdfsdf1.ru/manage/ground/remove',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'authToken'
                        )}`,
                        'Content-Type': 'application/json',
                    },
                    params: {
                        id: playground.id,
                    },
                }
            );
            console.log('Площадка удалена');
            onBack();
        } catch (error) {
            console.error(error);
        }
    };

    const handleSave = () => {
        const data = {
            id: playground.id,
            name: form.name,
            description: form.description,
            latitude: parseFloat(form.latitude),
            longitude: parseFloat(form.longitude),
            type: form.type,
            address: form.address,
            isFree: form.isFree === 'true',
            chatLink: form.chatLink,
        };

        axios
            .post('https://sdfsdfsdf1.ru/manage/ground/save', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        'authToken'
                    )}`,
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log('Данные сохранены', data);
                onBack();
            })
            .catch((error) => {
                console.error('Ошибка при сохранении:', error);
            });
    };

    const handleFileChange = async (e, index) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64String = reader.result.split(',')[1];
                const updatedImages = [...form.images];
                updatedImages[index] = base64String;

                // console.log(base64String);
                setForm({ ...form, images: updatedImages });

                try {
                    await axios.post(
                        'https://sdfsdfsdf1.ru/manage/ground/image/save',
                        {
                            groundId: playground.id,
                            id: imageIds[index],
                            src: base64String,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem(
                                    'authToken'
                                )}`,
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    console.log('Изображение успешно загружено');
                } catch (error) {
                    console.error('Ошибка при загрузке изображения:', error);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div className='add__wrapper'>
            <button className='btn__back' onClick={onBack}>
                <svg
                    width='55'
                    height='55'
                    viewBox='0 0 55 55'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M9.16626 27.4994L45.833 27.4994'
                        stroke='black'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        d='M20.624 38.9583C20.624 38.9583 9.1658 30.5194 9.16577 27.4999C9.16575 24.4804 20.6241 16.0416 20.6241 16.0416'
                        stroke='black'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </svg>
            </button>

            <div className='add__item'>
                <label className='add__label'>Название</label>
                <input
                    type='text'
                    name='name'
                    className='add__input'
                    value={form.name}
                    onChange={handleChange}
                />
            </div>

            <div className='add__item'>
                <label className='add__label'>Ссылка на чат</label>
                <input
                    type='text'
                    name='chatLink'
                    className='add__input'
                    value={form.chatLink}
                    onChange={handleChange}
                />
            </div>

            <div className='add__item'>
                <label className='add__label'>Вид спорта</label>
                <select
                    name='type'
                    className='add__input'
                    value={form.type}
                    onChange={handleChange}
                >
                    <option value='Football'>Football</option>
                    <option value='BasketBall'>BasketBall</option>
                    <option value='Tennis'>Tennis</option>
                </select>
            </div>

            <div className='add__item'>
                <label className='add__label'>Адрес</label>
                <input
                    type='text'
                    name='address'
                    className='add__input'
                    value={form.address}
                    onChange={handleChange}
                />
            </div>

            <div className='add__block'>
                <div className='add__item'>
                    <label className='add__label'>Широта</label>
                    <input
                        type='text'
                        name='latitude'
                        className='add__input'
                        value={form.latitude}
                        onChange={handleChange}
                    />
                </div>

                <div className='add__item'>
                    <label className='add__label'>Долгота</label>
                    <input
                        type='text'
                        name='longitude'
                        className='add__input'
                        value={form.longitude}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className='add__item'>
                <label className='add__label'>Описание</label>
                <textarea
                    type='text'
                    name='description'
                    className='add__textarea'
                    rows='5'
                    value={form.description}
                    onChange={handleChange}
                />
            </div>

            <div className='add__item'>
                <label className='add__label'>Тип</label>
                <div className='add__radio'>
                    <div className='add__radio_items'>
                        <input
                            type='radio'
                            name='isFree'
                            value='false'
                            id='false'
                            className='input__radio'
                            onChange={handleChange}
                            checked={form.isFree === 'false'}
                        />
                        <label for='false' className='add__label_radio'>
                            <span>Платная</span>
                        </label>
                    </div>
                    <div className='add__radio_items'>
                        <input
                            type='radio'
                            name='isFree'
                            value='true'
                            id='true'
                            className='input__radio'
                            onChange={handleChange}
                            checked={form.isFree === 'true'}
                        />
                        <label for='true' className='add__label_radio'>
                            <span>Бесплатная</span>
                        </label>
                    </div>
                </div>
            </div>

            <div className='add__item add__item_last'>
                <label className='add__label'>Фото</label>
                <div className='add__file'>
                    {[0, 1, 2, 3].map((index) => (
                        <div key={index}>
                            <label
                                htmlFor={`photo${index + 1}`}
                                className={
                                    form.images[index]
                                        ? 'label__photo_false'
                                        : 'label__photo_true'
                                }
                            >
                                {form.images[index] ? (
                                    <img
                                        src={
                                            form.images[index].length > 200
                                                ? `data:image/jpeg;base64,${form.images[index]}`
                                                : `https://sdfsdfsdf1.ru${form.images[index]}`
                                        }
                                        alt={`image-${index}`}
                                        className='image__play'
                                    />
                                ) : (
                                    ''
                                )}
                            </label>
                            <input
                                type='file'
                                id={`photo${index + 1}`}
                                className='input__photo'
                                onChange={(e) => handleFileChange(e, index)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            <div className='add__btn'>
                <button onClick={handleRemoveGround}>Удалить площадку</button>
                <button onClick={handleSave}>Сохранить изменения</button>
            </div>
        </div>
    );
};

export default Edit;
