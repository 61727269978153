import React, { useState } from 'react';
import axios from 'axios';

import logo from './../image/Build up белый 2.svg';

const Login = ({ clickLogin }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleBtn = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                'https://sdfsdfsdf1.ru/manage/auth',
                {
                    email,
                    password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.success) {
                clickLogin();

                const token = response.data.result;
                localStorage.setItem('authToken', token);
            }
        } catch (error) {
            console.log(email);
            console.log(password);
            console.error('Ошибка при входе:', error);
        }
    };

    return (
        <div className='login__container'>
            <div className='login__items'>
                <img src={logo} alt='logo' width='40%' />
                <form className='login__form'>
                    <input
                        type='email'
                        className='login__input'
                        placeholder='Логин'
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                        type='password'
                        className='login__input'
                        placeholder='Пароль'
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                        className='login__btn'
                        type='submit'
                        onClick={handleBtn}
                    >
                        Вход
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
