import axios from 'axios';
import React, { useState, useEffect } from 'react';

const Add = ({ onBack }) => {
    const [formData, setFormData] = useState({
        id: null,
        name: '',
        description: '',
        latitude: '',
        longitude: '',
        type: '',
        address: '',
        isFree: null,
        chatLink: '',
    });

    const [isFormValid, setIsFormValid] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    useEffect(() => {
        const {
            name,
            description,
            latitude,
            longitude,
            type,
            address,
            isFree,
            chatLink,
        } = formData;
        const isValid =
            name &&
            description &&
            latitude &&
            longitude &&
            type &&
            address &&
            isFree !== null &&
            chatLink;
        setIsFormValid(isValid);
    }, [formData]);

    const handleAddPlayground = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                'https://sdfsdfsdf1.ru/manage/ground/save',
                {
                    id: null,
                    name: formData.name,
                    description: formData.description,
                    latitude: parseFloat(formData.latitude),
                    longitude: parseFloat(formData.longitude),
                    type: formData.type,
                    address: formData.address,
                    isFree: formData.isFree === 'true',
                    chatLink: formData.chatLink,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'authToken'
                        )}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            console.log('Response:', response.data);
            onBack();
        } catch (error) {
            console.error('Error:', error);
            console.log('Response:', formData);
        }
    };

    return (
        <div className='add__wrapper'>
            <button className='btn__back' onClick={onBack}>
                <svg
                    width='55'
                    height='55'
                    viewBox='0 0 55 55'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <path
                        d='M9.16626 27.4994L45.833 27.4994'
                        stroke='black'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        d='M20.624 38.9583C20.624 38.9583 9.1658 30.5194 9.16577 27.4999C9.16575 24.4804 20.6241 16.0416 20.6241 16.0416'
                        stroke='black'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </svg>
            </button>

            <div className='add__item'>
                <label className='add__label'>Название</label>
                <input
                    type='text'
                    name='name'
                    required
                    className='add__input'
                    value={formData.name}
                    onChange={handleChange}
                />
            </div>

            <div className='add__item'>
                <label className='add__label'>Ссылка на чат</label>
                <input
                    type='text'
                    name='chatLink'
                    className='add__input'
                    value={formData.chatLink}
                    onChange={handleChange}
                />
            </div>

            <div className='add__item'>
                <label className='add__label'>Вид спорта</label>
                <select
                    name='type'
                    required
                    className='add__input'
                    value={formData.type}
                    onChange={handleChange}
                >
                    <option value='' disabled>
                        Выберите вид спорта
                    </option>
                    <option value='Football'>Football</option>
                    <option value='BasketBall'>BasketBall</option>
                    <option value='Tennis'>Tennis</option>
                </select>
            </div>

            <div className='add__item'>
                <label className='add__label'>Адрес</label>
                <input
                    type='text'
                    required
                    name='address'
                    className='add__input'
                    value={formData.address}
                    onChange={handleChange}
                />
            </div>

            <div className='add__block'>
                <div className='add__item'>
                    <label className='add__label'>Широта</label>
                    <input
                        type='text'
                        required
                        name='latitude'
                        className='add__input'
                        value={formData.latitude}
                        onChange={handleChange}
                    />
                </div>

                <div className='add__item'>
                    <label className='add__label'>Долгота</label>
                    <input
                        type='text'
                        required
                        name='longitude'
                        className='add__input'
                        value={formData.longitude}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className='add__item'>
                <label className='add__label'>Описание</label>
                <textarea
                    type='text'
                    required
                    name='description'
                    rows='5'
                    className='add__textarea'
                    value={formData.description}
                    onChange={handleChange}
                />
            </div>

            <div className='add__item'>
                <label className='add__label'>Тип</label>
                <div className='add__radio'>
                    <div className='add__radio_items'>
                        <input
                            type='radio'
                            name='isFree'
                            value='false'
                            id='false'
                            className='input__radio'
                            onChange={handleChange}
                            checked={formData.isFree === 'false'}
                        />
                        <label for='false' className='add__label_radio'>
                            <span>Платная</span>
                        </label>
                    </div>
                    <div className='add__radio_items'>
                        <input
                            type='radio'
                            name='isFree'
                            value='true'
                            id='true'
                            className='input__radio'
                            onChange={handleChange}
                            checked={formData.isFree === 'true'}
                        />
                        <label for='true' className='add__label_radio'>
                            <span>Бесплатная</span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='add__btn_new'>
                <button
                    type='button'
                    disabled={!isFormValid}
                    onClick={handleAddPlayground}
                    className={`add__button ${
                        !isFormValid ? 'add__button_disabled' : ''
                    }`}
                >
                    Создать площадку
                </button>
            </div>
        </div>
    );
};

export default Add;
