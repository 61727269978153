import React, { useEffect, useState } from 'react';
import Login from './pages/Login';
import Playgrounds from './pages/Playgrounds';
import Edit from './pages/Edit';
import Add from './pages/Add';

import './styles/reset.css';
import './styles/styles.css';
import './styles/media.css';

function App() {
    const [isLogin, setIsLogin] = useState(false);
    const [selectedPlayground, setSelectedPlayground] = useState(null);
    const [newPlayground, setNewPlayground] = useState(false);

    const handleLogin = () => {
        setIsLogin(true);
    };

    const handlePlaygroundClick = (playground) => {
        setSelectedPlayground(playground);
    };

    const backToPlaygrounds = () => {
        setSelectedPlayground(null);
        setNewPlayground(false);
    };

    const handleNewPlayground = () => {
        setNewPlayground(true);
    };

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');

        if (authToken) {
            setIsLogin(true);
        }
    }, []);

    return (
        <div className='App'>
            {isLogin ? (
                newPlayground ? (
                    <Add onBack={backToPlaygrounds} />
                ) : selectedPlayground ? (
                    <Edit
                        playground={selectedPlayground}
                        onBack={backToPlaygrounds}
                    />
                ) : (
                    <Playgrounds
                        onClick={handlePlaygroundClick}
                        onAdd={handleNewPlayground}
                    />
                )
            ) : (
                <Login clickLogin={handleLogin} />
            )}
        </div>
    );
}

export default App;
