import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';

import search from './../image/search-icon.svg';
import addIcon from './../image/add-circle-svgrepo-com.svg';

import tennisicon from './../image/tennis-map-icon.svg';
import footballicon from './../image/football-map-icon.svg';
import basketballicon from './../image/basketball-map-icon.svg';

import tennisiconTrue from './../image/tennis-icon-true.svg';
import footballiconTrue from './../image/football-icon-true.svg';
import basketballiconTrue from './../image/basketball-icon-true.svg';

const Playgrounds = ({ onClick, onAdd }) => {
    const [playgrounds, setPlaygrounds] = useState([]);
    const [searchChange, setSearch] = useState('');

    const handleLoadingData = async () => {
        try {
            const response = await axios.get(
                'https://sdfsdfsdf1.ru/api/grounds',
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            'authToken'
                        )}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            setPlaygrounds(response.data.result);
        } catch (error) {
            console.error('Ошибка при загрузке площадок: ', error);
        }
    };

    useEffect(() => {
        handleLoadingData();
    }, []);

    const getPlaygroundData = (type) => {
        switch (type) {
            case 'Tennis':
                return { icon: tennisicon, iconTrue: tennisiconTrue };

            case 'Football':
                return { icon: footballicon, iconTrue: footballiconTrue };

            case 'BasketBall':
                return { icon: basketballicon, iconTrue: basketballiconTrue };

            default:
                return { icon: '', iconTrue: '' };
        }
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const filteredPlaygrounds =
        searchChange.length >= 3
            ? playgrounds.filter((element) =>
                  element.name
                      .toLowerCase()
                      .includes(searchChange.toLowerCase())
              )
            : playgrounds;

    return (
        <div className='playgrounds__container'>
            <div className='playgrounds__wrapper'>
                <div className='playgrounds__side'>
                    <h1 className='side__btn'>Площадки</h1>
                    <div className='add__playgrounds side__btn' onClick={onAdd}>
                        <img src={addIcon} />
                        <h1>Добавить площадку</h1>
                    </div>
                </div>
                <div className='playgrounds__items'>
                    <div className='search__items'>
                        <input
                            className='play__input'
                            placeholder='Поиск'
                            onChange={handleSearch}
                        />
                        <img
                            className='search__icon'
                            src={search}
                            alt='search'
                            width='24px'
                        />
                    </div>
                    <div className='playgrounds__list'>
                        {filteredPlaygrounds.map((element) => {
                            const { icon, iconTrue } = getPlaygroundData(
                                element.type
                            );
                            return (
                                <div
                                    key={element.id}
                                    className='playgrounds__item'
                                    onClick={() => onClick(element)}
                                >
                                    <Swiper
                                        pagination={true}
                                        modules={[Pagination]}
                                        className='myNewSwiper'
                                    >
                                        {element.images.map((img, index) => (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={`https://sdfsdfsdf1.ru${img}`}
                                                    alt={index}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className='playgrounds__item_about'>
                                        <h1>{element.name}</h1>
                                        <p>{element.description}</p>
                                        <img
                                            src={
                                                element.isPlayersRequired
                                                    ? iconTrue
                                                    : icon
                                            }
                                            height='30px'
                                            alt='icon'
                                            className='play__icon'
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Playgrounds;
